@use 'variables' as *;

@mixin for-tablet {
    @media screen and (max-width: $md-breakpoint + 0px) {
        @content;
    }
}

@mixin for-mobile {
    @media screen and (max-width: $sm-breakpoint + 0px) {
        @content;
    }
}
