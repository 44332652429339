@use '@styles/config' as *;

.workspace {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    // padding: 0 20px 13px;

    &__footer {
        display: none;
    }
}

.iframe-container {
    flex: 1;
    overflow: hidden;

    @include for-mobile {
        align-self: stretch;
    }
}
iframe {
    outline: unset;
    border: none;
}

.room {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    min-height: 0;
    // margin-top: 12px;

    &__mobile-services {
        display: none;
    }

    &__info {
        display: none;
    }
}

@include for-mobile {
    .workspace {
        justify-content: space-between;
        flex-direction: column;
        // padding: 0.625rem 0.875rem 0.875rem;

        &__footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 10%;
            // margin: 5px;
            padding: 10px;
            background: #ffffff;

            &__item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 4.875rem;
                height: 100%;
                border: none;
                border-radius: 0.625rem;
                background: transparent;
                outline: none;
                cursor: pointer;
                transition: all 0.3s ease;

                img {
                    opacity: 0.5;
                }

                p {
                    margin: 0.75rem 0 0;
                    font-weight: 500;
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    color: #68a877;
                    transition: all 0.3s ease;
                }

                &.active {
                    background: #e6f3e1;

                    img {
                        opacity: 1;
                    }

                    p {
                        color: #127050;
                    }
                }
            }
        }
    }

    .messenger__form-input {
        margin: 0;
    }

    .room {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        // height: 78%;
        margin: 0;
        flex: 1;
        // flex: none;

        &_chat {
            .room__toolbar {
                top: auto;
                bottom: 3rem;
            }
        }

        .canvas {
            width: 43.75rem;
            height: 24.125rem;

            canvas {
                width: 43.75rem;
                height: 24.125rem;
            }

            &__video {
                &_my {
                    right: 2.25rem;
                }
                &_other {
                    left: 2.25rem;
                }
            }
        }

        &__toolbar {
            position: absolute;
            // top: 36%;
            bottom: 80px;
            left: 50%;
            transform: translateX(-50%);
            // right: auto;
            z-index: 100;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 12rem;
            height: 3.5rem;
            background: #f8f8f8;
            border-radius: 1.563rem;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

            &__item {
                width: 2.5rem;
                height: 2.5rem;
                border: none;
                outline: none;
                background: #eee9e9;
                border-radius: 50%;
                opacity: 0.6;
                transition: all 0.3s ease;

                &.active {
                    opacity: 1;
                }
            }
        }

        &__mobile-services {
            width: 100%;
            height: 49%;
        }

        &__info {
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 49%;
            padding: 1rem 2rem 1.5rem;
            background-color: #f8f8f8;
            border-radius: 0.625rem;

            &.active {
                display: flex;
            }

            &.hidden {
                display: none;
            }

            &__title {
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1rem;
                text-align: center;
            }

            &__content {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin: 2% 0;

                &-left {
                    width: 55%;
                    span {
                        display: block;
                        margin: 0 0 0.75rem;
                        font-weight: 600;
                        font-size: 0.625rem;
                        line-height: 0.75rem;
                        color: #a09f9f;
                    }

                    p {
                        margin: 0 0 1.5rem;
                        font-weight: 500;
                        font-size: 0.813rem;
                        line-height: 0.938rem;
                        color: #161616;
                    }
                }

                &-right {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 40%;

                    span {
                        font-weight: 500;
                        font-size: 0.625rem;
                        line-height: 0.75rem;
                        color: #a09f9f;
                        text-align: center;
                    }

                    p {
                        margin: 0.25rem 0;
                        font-weight: 600;
                        font-size: 0.688rem;
                        line-height: 0.813rem;
                        color: #161616;
                        text-align: center;
                    }
                }

                &-avatar {
                    overflow: hidden;
                    width: 3.75rem;
                    height: 3.75rem;
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            &__wrapper {
                display: flex;
                align-items: center;
                width: 90%;
                max-width: 20rem;

                p {
                    margin: 0 0 0 1.5rem;
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 1rem;
                    color: #d9d9d9;
                }
            }
        }
    }
}

@media screen and (max-height: 414px) {
    .workspace {
        flex-direction: row;
        justify-content: space-between;
        // padding: 0.875% 0.688% 0.875% 1.5%;

        .header {
            flex-direction: column;
            width: auto;
            // margin: 0 1.5% 0 0;
            padding: 0;
            border: none;
        }

        &__footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: auto;
            height: 100%;
            margin: 0 0 0 1%;
            background: #ffffff;

            &__item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 4.875rem;
                height: 5.375rem;
                border: none;
                border-radius: 0.625rem;
                background: transparent;
                outline: none;
                cursor: pointer;
                transition: all 0.3s ease;

                img {
                    opacity: 0.5;
                }

                p {
                    margin: 1rem 0 0;
                    font-weight: 500;
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    color: #68a877;
                    transition: all 0.3s ease;
                }

                &.active {
                    background: #e6f3e1;

                    img {
                        opacity: 1;
                    }

                    p {
                        color: #127050;
                    }
                }
            }
        }
    }

    .messenger__form-input {
        margin: 0;
    }

    .room {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
        width: auto;
        height: 100%;
        margin: 0;
        flex-direction: row;
        align-items: stretch;

        &_chat {
            .room__toolbar {
                top: auto;
                bottom: 3.5rem;
            }
        }

        .canvas {
            width: 43.75rem;

            canvas {
                width: 43.75rem;
                height: 24.125rem;
            }

            &__video {
                &_my {
                    right: 2.25rem;
                }
                &_other {
                    left: 2.25rem;
                }
            }
        }

        &__toolbar {
            position: absolute;
            top: auto;
            bottom: 1rem;
            left: auto;
            right: auto;
            z-index: 100;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 20rem;
            height: 3.5rem;
            background: #f8f8f8;
            border-radius: 1.563rem;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

            @media screen and (max-height: 414px) {
                left: 50%;
                transform: translateX(-50%);
                right: auto;
            }

            &__item {
                width: 2.875rem;
                height: 2.875rem;
                border: none;
                outline: none;
                background: #eee9e9;
                border-radius: 50%;
                opacity: 0.6;
                transition: all 0.3s ease;

                &.active {
                    opacity: 1;
                }
            }
        }

        &__mobile-services {
            width: 100%;
            height: 100%;
        }

        &__info {
            display: none !important;
        }
    }
}
