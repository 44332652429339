.chat-record {
  &__title {
    text-align: center;
    margin-bottom: 43px;
    margin-top: 15px;

    h1 {
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
    }
  }
}