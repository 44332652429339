.button {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 16px;
    background: #f9a61a;
    border-radius: 10px;
    padding: 11px 26px;
    border: none;
    cursor: pointer;

    &.secondary {
        width: 100%;
        padding: 0.75rem 2rem;
        background: transparent;
        border: 2px solid #aeeba8;
        border-radius: 0.625rem;
        font-size: 0.75rem;
        font-weight: 600;
        color: #666666;
        transition: background-color 0.3s ease, border-color 0.3s ease;

        &:hover {
            border-color: #e6f3e1;
        }

        &:active {
            background: #aeeba8;
        }
    }

    &.tertiary {
        padding: 1rem 2rem;
        border-radius: 1.875rem;
        font-weight: 500;
        font-size: 1.25rem;
        text-transform: none;
    }

    &:disabled {
        background: #adacab;
        cursor: default;
    }
}
