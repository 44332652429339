@use '../../styles/config/index' as *;

.notice {
    &__wrapper {
        position: absolute;
        top: 3.5rem;
        right: 0.5rem;
        overflow-y: auto;

        display: flex;
        align-items: flex-start;
        width: 20rem;
        padding: 0.5rem 0.5rem 1rem;
        background-color: #fff4e1;
        border-radius: 0.625rem;

        transform: translateX(150%);
        animation: ani 1s forwards;

        @keyframes ani {
            0% {
                transform: translateX(150%);
            }
            100% {
                transform: translateY(0);
            }
        }
    }

    &__title {
        margin: 0.5rem 0 0.5rem;
        font-size: 1.25rem;
        font-weight: normal;
        text-align: center;
        color: #f9a61a;
    }

    &__text {
        font-size: 0.75rem;
        color: #555555;
    }

    &__icon {
        margin: 0.5rem 1.5rem 0 1rem;
    }

    &__close {
        cursor: pointer;
    }
}
