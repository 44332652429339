@use '@styles/config' as *;

.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    &-inner-container {
        padding: 2rem 3.75rem;
        min-height: 100%;

        @include for-mobile {
            padding: 1.5rem 1rem;
        }
    }
    &-modal {
        width: 42.5rem;
        max-height: 95vh;
        overflow-y: auto;
        position: absolute;
        z-index: 9999;
        border-radius: 0.938rem;
        background: white;
        box-shadow: 0px 0.25rem 1.563rem rgba(0, 0, 0, 0.25);

        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
        scrollbar-color: #888 #c2d2e4;
        scrollbar-width: thin;

        &__close {
            position: relative;
            display: flex;
            justify-content: flex-end;
            margin: 0 0 1rem;

            div {
                position: absolute;
                top: 0;
                right: -2rem;
                display: flex;
                align-items: center;
                padding: 0.1rem 0.5rem 0.1rem 0.25rem;
                background: #d7eed5;
                border-radius: 0.781rem;
                cursor: pointer;
            }

            span {
                font-weight: 700;
                font-size: 0.75rem;
                text-transform: uppercase;
                color: #22614d;
            }
        }

        &__title {
            font-weight: 600;
            font-size: 1.125rem;
            text-align: center;
            color: #2a2b2a;
        }

        &__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 1.625rem 0 2.625rem;

            p {
                width: 55%;
                font-weight: 400;
                font-size: 1rem;
                align-items: center;
                color: #000000;
            }
        }

        &__icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 2.75rem 0 auto;

            &:last-child {
                margin: 0 1rem 0 0;
            }

            &__wrapper {
                position: relative;
            }

            &__status {
                position: absolute;
                bottom: 0;
                right: 0;
            }

            span {
                margin: 0.8rem 0 0;
                font-weight: 500;
                font-size: 0.75rem;
                color: #127050;
            }
        }

        &__tabs {
            display: flex;
            width: 100%;
            padding: 0 0 1.25rem;
            border-bottom: 0.25rem solid #eceff1;

            &__item {
                position: relative;
                margin: 0 1.25rem 0 0;
                padding: 0 1rem;
                font-weight: 700;
                font-size: 0.938rem;
                color: #808080;
                user-select: none;
                cursor: pointer;
                transition: all 0.3s ease;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: -1.5rem;
                    left: 0;
                    width: 0;
                    height: 0.25rem;
                    background-color: transparent;
                    border-radius: 0.25rem;
                    transition: all 0.3s ease;
                }

                &.active {
                    color: #61bb57;

                    &::before {
                        width: 100%;
                        background-color: #61bb57;
                    }
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 68%;
            margin: 2.75rem 0 0;
        }

        &__chrome {
            &__wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__text {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1rem;
                color: #000000;

                &.secondary {
                    font-weight: 600;
                    font-size: 0.875rem;
                    color: #61bb57;
                }
            }

            &__input {
                display: flex;
                width: 15rem;
                padding: 0.875rem;
                border: 0.125rem solid #e6f3e1;
                border-radius: 0.313rem;

                span {
                    margin: 0 0 0 0.875rem;
                }
            }

            &__settings {
                display: flex;
                align-items: center;
                width: 15rem;
                margin: 2.938rem 0 3.313rem;
                padding: 0.5rem 0;
                border-top: 1px solid #e6f3e1;
                border-bottom: 1px solid #e6f3e1;

                span {
                    margin: 0 auto 0 1.25rem;
                }
            }

            &__permissions {
                width: 15rem;
                padding: 0.5rem 0;
                border-top: 1px solid #e6f3e1;
                border-bottom: 1px solid #e6f3e1;

                &__flex {
                    display: flex;
                    align-items: center;

                    span {
                        margin: 0 auto 0 1.25rem;
                    }
                }

                &__wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 4.625rem;
                    padding: 0.35rem 0.3rem;
                    background: #d7eed5;
                    border-radius: 0.313rem;

                    span {
                        margin: 0 !important;
                        font-weight: 600;
                        font-size: 0.563rem;
                        color: #61bb57;
                    }

                    img {
                        margin: 0.25rem 0 0;
                    }
                }

                &__line {
                    width: 100%;
                    height: 1px;
                    margin: 0.5rem 0;
                    background: #e6f3e1;
                }

                &__microphone {
                    margin: 0 0.25rem;
                }
            }

            &__refresh {
                margin: 3.125rem 0;
            }
        }

        &__yandex {
            &__wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__text {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1rem;
                color: #000000;

                &.secondary {
                    font-weight: 600;
                    font-size: 0.875rem;
                    color: #61bb57;
                }
            }

            &__input {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 16rem;
                padding: 0.7rem 0.75rem;
                border: 0.125rem solid #e6f3e1;
                border-radius: 0.313rem;

                &__icons {
                    display: flex;
                    align-items: center;
                }

                &__camera {
                    width: 1.125rem;
                    height: 0.688rem;
                }

                &__microphone {
                    width: 0.725rem;
                    height: 1.25rem;
                    margin: 0 0.75rem;
                }
            }

            &__permissions {
                width: 16rem;
                margin: 2.5rem 0 0;
                padding: 0.5rem 0;
                border-top: 1px solid #e6f3e1;
                border-bottom: 1px solid #e6f3e1;

                &__flex {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                &__wrapper {
                    display: flex;
                    align-items: center;
                    margin: 0.5rem 0;
                }

                &__switcher {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 1.563rem;
                    height: 0.875rem;
                    margin: 0 0.5rem 0 0;
                    padding: 0.1rem;
                    border-radius: 0.938rem;
                    background: #d7eed5;

                    &__ball {
                        width: 0.625rem;
                        height: 0.625rem;
                        border-radius: 50%;
                        background: white;
                    }
                }

                &__text {
                    font-weight: 600;
                    font-size: 0.563rem;
                    color: #61bb57;
                }

                &__line {
                    width: 100%;
                    height: 1px;
                    margin: 0.5rem 0;
                    background: #e6f3e1;
                }

                &__camera {
                    width: 1.188rem;
                    height: 0.813rem;
                }

                &__microphone {
                    width: 0.688rem;
                    height: 1.188rem;
                    margin: 0 0.3rem;
                }
            }

            &__refresh {
                margin: 3.125rem 0 4.25rem;
            }
        }

        &__firefox {
            &__wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.margin {
                    margin: 0 0 1.5rem;
                }
            }

            &__text {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1rem;
                color: #000000;

                &.secondary {
                    font-weight: 600;
                    font-size: 0.875rem;
                    color: #61bb57;
                }

                &.margin {
                    margin: 2rem 0 0;
                }
            }

            &__input {
                display: flex;
                align-items: center;
                width: 15rem;
                padding: 0.875rem;
                border: 0.125rem solid #e6f3e1;
                border-radius: 0.313rem;

                span {
                    margin: 0 0 0 1.125rem;
                }

                &__camera {
                    width: 1rem;
                    height: 0.688rem;
                    margin: 0 0.625rem 0 0;
                }
            }

            &__settings {
                width: 15rem;
                margin: 1rem 0;
                padding: 0.688rem 0;
                border-top: 1px solid #e6f3e1;
                border-bottom: 1px solid #e6f3e1;

                &__flex {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 1rem 0 0;
                    font-weight: 600;
                    color: #61bb57;

                    span {
                        font-size: 0.563rem;
                    }

                    p {
                        font-size: 0.438rem;
                        text-align: right;
                    }
                }

                &__microphone {
                    position: relative;

                    &__microphone {
                        width: 0.688rem;
                        height: 1.188rem;
                        margin: 0 0.35rem;
                    }

                    &__camera {
                        width: 1.063rem;
                        height: 0.688rem;
                        margin: 0 0.25rem;
                    }

                    &__line {
                        position: absolute;
                        top: 0;
                        left: 0.15rem;
                    }
                }
            }

            &__permissions {
                display: flex;
                align-items: flex-start;
                width: 15rem;
                padding: 0.875rem 0.75rem 0.625rem;
                border: 1px solid #d7eed5;
                border-radius: 0.313rem;

                &__camera {
                    margin: 0.25rem 0.563rem 0 0;
                }

                &__content {
                    display: flex;
                    flex-direction: column;

                    &__subtitle {
                        margin: 0 0 0.25rem;
                        font-size: 0.563rem;
                        font-weight: 600;
                        color: #61bb57;
                    }

                    &__flex {
                        span {
                            font-weight: 600;
                            font-size: 0.563rem;
                            color: #61bb57;
                        }

                        div {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            margin: 0.25rem 0 0.5rem;
                            padding: 0.25rem 0.375rem;
                            border: 1px solid #d7eed5;
                            border-radius: 0.313rem;
                        }
                    }

                    &__buttons {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0.25rem 0 0;
                    }

                    &__button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 5.125rem;
                        height: 1.25rem;
                        background: #d7eed5;
                        border-radius: 0.313rem;
                        font-weight: 600;
                        font-size: 0.563rem;
                        color: #61bb57;
                    }
                }
            }
        }

        &__opera {
            &__wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 0.5rem;
            }

            &__text {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1rem;
                color: #000000;

                &.secondary {
                    font-weight: 600;
                    font-size: 0.875rem;
                    color: #61bb57;
                }
            }

            &__input {
                display: flex;
                align-items: center;
                width: 16rem;
                height: 2.75rem;
                padding: 0 0.75rem;
                border: 0.125rem solid #e6f3e1;
                border-radius: 0.313rem;

                &__camera {
                    width: 1rem;
                    height: 0.688rem;
                    margin: 0 0.625rem 0 0;
                }

                &__line {
                    width: 2px;
                    height: 2.75rem;
                    margin: 0 1.125rem;
                    background: #e6f3e1;
                }
            }

            &__permissions {
                display: flex;
                align-items: flex-start;
                width: 16rem;
                padding: 0.875rem 0.75rem 0.625rem;
                border: 1px solid #d7eed5;
                border-radius: 0.313rem;

                &__camera {
                    width: 1rem;
                    height: 0.688rem;
                    margin: 0.035rem 0.563rem 0 0;
                }

                &__content {
                    display: flex;
                    flex-direction: column;

                    &__subtitle {
                        margin: 0 0 0.25rem;
                        font-weight: 600;
                        font-size: 0.563rem;
                        color: #61bb57;
                    }

                    &__text {
                        margin: 0.25rem 0;
                        font-weight: 600;
                        font-size: 0.438rem;
                        color: #61bb57;
                    }

                    &__button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 9.688rem;
                        height: 1.25rem;
                        margin: 0.25rem 0 0;
                        background: #d7eed5;
                        border-radius: 0.313rem;
                        font-weight: 600;
                        font-size: 0.563rem;
                        color: #61bb57;
                    }
                }
            }

            &__settings {
                display: flex;
                flex-direction: column;
                width: 16rem;
                padding: 0.875rem 0.75rem 0.625rem;
                border: 1px solid #d7eed5;
                border-radius: 0.313rem;

                &__subtitle {
                    font-weight: 600;
                    font-size: 0.563rem;
                    color: #61bb57;
                }

                &__text {
                    margin: 1rem 0;
                    font-weight: 600;
                    font-size: 0.438rem;
                    color: #61bb57;
                }

                &__flex {
                    display: flex;
                    align-items: center;
                    margin: 0.25rem 0;

                    span {
                        font-weight: 600;
                        font-size: 0.563rem;
                        color: #61bb57;
                    }

                    div {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 0.25rem 0.375rem;
                        border: 1px solid #d7eed5;
                        border-radius: 0.313rem;
                    }
                }

                &__camera {
                    width: 1.188rem;
                    height: 0.813rem;
                    margin: 0 0.5rem 0 0;
                }

                &__microphone {
                    width: 0.688rem;
                    height: 1.188rem;
                    margin: 0 0.75rem 0 0.25rem;
                }

                &__buttons {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin: 0.5rem 0 0;
                }

                &__button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 5.125rem;
                    height: 1.25rem;
                    margin: 0 0 0 0.5rem;
                    background: #d7eed5;
                    border-radius: 0.313rem;
                    font-weight: 600;
                    font-size: 0.563rem;
                    color: #61bb57;
                }
            }
        }

        &__edge {
            &__wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 3rem;
            }

            &__subtitle {
                font-weight: 600;
                font-size: 0.875rem;
                color: #61bb57;

                &.margin {
                    margin: 0.5rem 0;
                }
            }

            &__text {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1rem;
                color: #000000;

                &.secondary {
                    font-weight: 600;
                    font-size: 0.563rem;
                    line-height: 0.688rem;
                    color: #61bb57;
                }

                &.margin {
                    margin: 0.25rem 0;
                }
            }

            &__confidentiality {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 12.5rem;
                height: 7.188rem;
                margin: 0 2.5rem 0 0;
                border: 1px solid #e6f3e1;
                border-radius: 0.313rem;

                &__icons {
                    display: flex;
                    align-items: center;
                }

                &__camera {
                    width: 1.125rem;
                    height: 0.688rem;
                }

                &__microphone {
                    width: 0.725rem;
                    height: 1.25rem;
                    margin: 0 0.75rem;
                }
            }

            &__permissions {
                width: 15rem;

                &__flex {
                    display: flex;
                    flex-direction: column;
                }

                &__wrapper {
                    display: flex;
                    align-items: center;
                    margin: 0.5rem 0;
                }

                &__switcher {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 1.563rem;
                    height: 0.875rem;
                    margin: 0 0.5rem 0 0;
                    padding: 0.1rem;
                    border-radius: 0.938rem;
                    background: #d7eed5;

                    &__ball {
                        width: 0.625rem;
                        height: 0.625rem;
                        border-radius: 50%;
                        background: white;
                    }
                }
            }
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__item {
                margin: 1.5rem 0 0;
                outline: none;
                border: none;
                background: transparent;
                font-weight: 400;
                font-size: 0.875rem;
                color: #61bb57;

                a {
                    color: #61bb57;
                    text-decoration: none;
                    transition: opacity 0.3s ease;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}

@include for-mobile {
    .container {
        position: absolute;
        align-items: flex-start;
        padding: 0 !important;

        &__bg {
            position: fixed;
        }

        &-modal {
            width: 100%;
            max-width: 42.5rem;
            height: auto;
            min-height: 100vh;
            border-radius: 0;

            &__close {
                div {
                    position: static;
                    padding: 0.25rem;
                    border-radius: 50%;
                }

                span {
                    display: none;
                }
            }

            &__wrapper {
                flex-wrap: wrap;
                justify-content: center;
                margin: 1rem 0 2rem;

                p {
                    width: 100%;
                    margin: 0 0 1rem;
                    font-size: 0.75rem;
                    text-align: center;
                }
            }

            &__icon {
                margin: 0 2.75rem 0 0;

                &:last-child {
                    margin: 0;
                }

                span {
                    margin: 0.5rem 0 0;
                    font-size: 0.6rem;
                }
            }

            &__tabs {
                padding: 0 0 1rem;

                &__item {
                    margin: 0 2% 0 0;
                    padding: 0 0.5rem;
                    font-weight: 600;
                    font-size: 0.75rem;

                    &::before {
                        bottom: -1.25rem;
                    }
                }
            }

            &__content {
                height: auto;
                min-height: 65vh;
                margin: 2rem 0 0;
            }

            &__chrome {
                &__wrapper {
                    flex-direction: column;
                    margin: 0 0 2rem;
                }

                &__text {
                    margin: 0.5rem 0 1rem;
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    text-align: center;

                    &.secondary {
                        font-size: 0.75rem;
                    }

                    br {
                        display: none;
                    }
                }

                &__settings {
                    margin: 0;
                }

                &__refresh {
                    margin: 0 0 3rem;
                }
            }

            &__yandex {
                &__wrapper {
                    flex-direction: column;
                    margin: 0 0 2rem;
                }

                &__text {
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    text-align: center;

                    &.secondary {
                        font-size: 0.75rem;
                    }

                    &.margin {
                        margin: 0.5rem 0 1rem;
                    }

                    br {
                        display: none;
                    }
                }

                &__permissions {
                    margin: 0;
                }

                &__refresh {
                    margin: 2.5rem 0 3rem;
                }
            }

            &__firefox {
                &__wrapper {
                    flex-direction: column;
                    margin: 0 0 2rem;

                    &.margin {
                        margin: 0 0 2rem;
                    }
                }

                &__text {
                    margin: 0.5rem 0 1rem;
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    text-align: center;

                    &.secondary {
                        font-size: 0.75rem;
                    }

                    &.margin {
                        margin: 0.5rem 0;
                    }

                    br {
                        display: none;
                    }
                }

                &__settings {
                    margin: 0.5rem 0;
                }
            }

            &__opera {
                &__wrapper {
                    flex-direction: column;
                    margin: 0 0 2rem;
                }

                &__text {
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    text-align: center;

                    &.secondary {
                        font-size: 0.75rem;
                    }

                    &.margin {
                        margin: 0.5rem 0 1rem;
                    }

                    br {
                        display: none;
                    }
                }
            }

            &__edge {
                &__wrapper {
                    flex-direction: column;
                    margin: 0 0 2rem;
                }

                &__subtitle {
                    font-size: 0.75rem;
                }

                &__text {
                    margin: 0.5rem 0 1rem;
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    text-align: center;

                    &.secondary {
                        margin: 0;
                        font-size: 0.5rem;
                        line-height: 0.6rem;
                        text-align: left;
                    }

                    &.margin {
                        margin: 0.25rem 0;
                    }

                    br {
                        display: none;
                    }
                }

                &__confidentiality {
                    margin: 0;
                }
            }

            &__buttons {
                &__item {
                    font-size: 0.75rem;
                }
            }
        }
    }
}
