@use '@styles/config' as *;

.messenger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__messages {
        padding-right: 5px;
        overflow-y: auto;
        margin-top: auto;
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
        scrollbar-color: #888 #c2d2e4;
        scrollbar-width: thin;
    }
    &__message {
        margin-bottom: 3px;
        span {
            margin-bottom: 5px;
            margin-left: 10px;
        }

        &-text {
            padding: 10px 15px;
            max-width: 350px;
            p {
                font-size: 15px;
                font-weight: 400;
            }
        }
        &-images {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        &-image {
            overflow: hidden;
            width: 10rem;
            margin: 0.5rem 0;
            border-radius: 1rem;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            img {
                width: 100%;
                height: 100%;
            }
        }
        &-pdf {
            margin: 0.5rem 0;
            font-weight: 300;
            font-size: 0.75rem;
            color: #ffffff;
            text-decoration: none;
            transition: all 0.3s ease;

            &:hover {
                color: #46c5eb;
            }
        }
        &-file {
            object-fit: cover;
            img {
                object-fit: cover;
                max-width: 240px;
                max-height: 62px;
            }
        }
        &-time {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: rgba(0, 0, 0, 0.3);
        }
        &_outgoing + &_incoming {
            margin-bottom: 12px;
        }
        &_incoming {
            .messenger__message-time {
                text-align: center;
            }
            font-size: 15px;
            .messenger__message-text {
                background: rgba(0, 0, 0, 0.06);
                border-radius: 18px;
                text-align: left;
            }
            .messenger__message-pdf {
                color: #000;

                &:hover {
                    color: #46c5eb;
                }
            }
        }
        &_outgoing {
            font-size: 17px;
            align-self: flex-end;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin: 0 0 0.75rem;
            .messenger__message-text {
                background: #268e55;
                color: #fff;
                border-radius: 18px;
                text-align: left;
            }
        }
        &-teacher {
            font-size: 9px;
            margin-bottom: 15px;
            display: inline-block;
            color: #c4c4c4;
        }
    }
    &__media {
        display: flex;
        width: 100%;
        margin: 0 0 5px;
        &__item {
            position: relative;
            width: 100px;
            height: 100px;
            margin: 0 5px 0 0;
            &__pdf {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
            &__image {
                width: 100px;
                height: 100px;
                &__delete {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    z-index: 15;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #178e6b;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    cursor: pointer;

                    img {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }
    &__form {
        display: flex;
        align-items: center;
        margin-top: 8px;
        &-input {
            width: 100%;
            margin-left: 17px;
            margin-right: 10px;
            position: relative;
            display: flex;
            align-items: center;
            input {
                line-height: 20px;
                width: 100%;
                min-width: 314px;
                background: rgba(0, 0, 0, 0.05);
                border-radius: 18px;
                border: none;
                padding: 8px 6px 8px 15px;
                color: #999999;
            }
        }
        &-send {
            margin-top: 6px;
            cursor: pointer;
            line-height: 0;
            height: 30px;
            width: 30px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        &-download {
            label {
                cursor: pointer;
                line-height: 0;
            }
        }
    }
}
@include for-tablet {
    .messenger {
        &__form {
            &-input {
                margin-right: 0;
                input {
                    min-width: auto;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
            &-send {
                margin-left: 8px;
            }
        }
    }
}
@include for-mobile {
    .messenger {
        &__messages {
            &::-webkit-scrollbar {
                width: 4px;
            }
        }

        &__form {
            &-input {
                input {
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
            }
            &-download {
                margin-right: 8px;
            }
        }
    }
}
@media screen and (max-height: 414px) {
    .messenger {
        &__messages {
            &::-webkit-scrollbar {
                width: 4px;
            }
        }
    }
}
