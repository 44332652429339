@use 'config' as *;

html,
body {
    margin: 0;
}
.container {
    padding: 0 20px;
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    font-family: $p-font;
}
.create_room {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.create_room__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    background: #f1e6e6;
    border: 1px solid #c4c4c4;
    border-radius: 1rem;
    font-size: 1.125rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.create_room__button:hover {
    border-color: #000000;
}

// .room_canvas {
//     display: flex;
//     justify-content: flex-start;
//     align-items: flex-start;
//     width: 70%;
//     height: 100%;
// }

// canvas {
//     border: 1px solid #c4c4c4;
//     background-color: white;
//     border-radius: 0.5rem;
//     box-shadow: 0 0 0.25rem 0.1rem rgba(0, 0, 0, 0.1);
// }

// .room_services {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     width: 30.75rem;
//     height: 100%;
// }

// .room_form {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     width: 100%;
// }

// .room_form__input {
//     width: 80%;
//     margin: 1rem 0;
//     font-size: 1rem;
//     padding: 0.75rem;
//     border: 1px solid #c4c4c4;
//     border-radius: 0.25rem;
//     outline: none;
//     box-shadow: 0 0 0.25rem 0.1rem rgba(0, 0, 0, 0.1);
// }

// .room_form__button {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 0.75rem;
//     background: #f1e6e6;
//     border: 1px solid #c4c4c4;
//     border-radius: 0.25rem;
//     font-size: 1.125rem;
//     cursor: pointer;
//     transition: all 0.3s ease;
//     box-shadow: 0 0 0.25rem 0.1rem rgba(0, 0, 0, 0.1);
// }

// .room_form__button:hover {
//     border-color: #000000;
// }

.video {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    // background: #ccc;
    height: auto;
}
