.lesson-record {
  &__title {
    text-align: center;
    margin-bottom: 43px;
    margin-top: 15px;

    h1 {
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;

    }
  }

  &__content {
    margin-left: 26px;
    display: flex;

    &-video {
      flex-basis: 70%;
      video {
        width: 100%;
        height: 100%;
      }
    }

    &-record-buttons {
      flex-basis: 30%;
      padding-top: 94px;

      &-chat {
        margin-bottom: 42px;
      }

      &-chat, &-lesson {
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
          width: 80px;
          height: 80px;
          background: #FDC133;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h5 {
          margin-top: 31px;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}