@use '@styles/config' as *;

.header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e4e4e4;
    padding: 12px 18px;

    &.active {
        transition: 0.4s ease-in-out;
    }

    &__logo {
        flex-basis: 28%;

        img {
            width: 2.375rem;
            height: 2.5rem;
        }
    }

    &__toolbar {
        margin-top: 15px;
    }

    &__timer {
        // margin-top: 17px;
        align-self: center;

        &_gray {
            p {
                color: #979797;
            }
        }

        &_red {
            p {
                color: #f7b59c;
            }
        }

        p {
            font-weight: 500;
            font-size: 15px;

            span {
                font-size: 12px;
            }
        }
    }

    &__profile {
        &-wrap,
        &-card {
            display: flex;
            align-items: center;
            white-space: nowrap;

            p {
                color: #7b7c7c;
                font-size: 12px;
                line-height: 14px;
                margin-left: auto;
                font-weight: 500;
            }
            &-avatar {
                overflow: hidden;
                width: 50px;
                height: 50px;
                border-radius: 50%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &-content {
            max-height: 0;
            overflow: hidden;
            transition: 0.4s ease-in-out;

            &.active {
                max-height: 117px;
                margin-top: 18px;
            }
        }

        &-avatar {
            overflow: hidden;
            margin-right: 16px;
            margin-left: 9px;
            width: 50px;
            height: 50px;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-arrow {
            border: none;
            background: transparent;
            padding: 5px;
            transition: 0.3s ease-in-out;
            cursor: pointer;

            &.active {
                transform: rotate(180deg);
            }
        }

        &-card {
            margin-bottom: 9px;

            &-avatar {
                margin-right: 13px;
                margin-left: 9px;
            }

            &-radio {
                label {
                    display: block;
                    width: 24px;
                    height: 24px;
                    border: 1px solid #c4c4c4;
                    border-radius: 100%;
                    padding: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    input {
                        display: none;
                    }

                    .radio-content::after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        background: #62bb58;
                        display: block;
                        border-radius: 100%;
                        transform: scale(0);
                    }

                    input[type='radio']:checked + .radio-content::after {
                        transform: scale(1);
                    }
                }
            }
        }
    }

    &__settings {
        display: none;
    }
}

.toolbar {
    display: flex;
    align-items: flex-start;
    flex-basis: 15%;
    min-width: 185px;

    button {
        border: none;
        background: transparent;
        margin-right: 31px;
        cursor: pointer;
        padding: 0;
        min-height: 0;
        min-width: 0;
        &:disabled {
            opacity: 0.4;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    .received-message {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: red;
        }
    }
}

@include for-tablet {
    .header {
        padding-top: 7px;
        align-items: flex-start;

        &__logo {
            flex-basis: 30%;
        }

        &__toolbar {
            margin-top: 11px;
        }

        &__profile {
            p {
                font-size: 10px;
            }

            &-avatar,
            &-card-avatar {
                margin-right: 11px;
                width: 40px;
                height: 40px;
            }
        }

        // &__timer {
        //     display: none;
        // }
    }
}

@include for-mobile {
    .header {
        align-items: center;
        width: 100%;
        height: 8%;
        // margin: 0 0 2%;
        padding: 0;
        border: none;

        &__logo {
            img {
                width: 3.125rem;
                height: 3.125rem;
            }
        }

        &__toolbar {
            display: none;
        }

        &__profile {
            &-card-avatar,
            &-avatar {
                width: 2rem;
                height: 2rem;
            }
        }
    }

    .toolbar {
        max-width: 22.5rem;
        margin: 0 auto;
    }

    .settings {
        .header__profile {
            display: none;
        }

        .header__settings {
            display: block;
            img {
                width: 3.125rem;
                height: 3.125rem;
            }
        }
    }
}

@media screen and (max-height: 414px) {
    .header {
        align-items: center;
        height: 100%;

        &__logo {
            img {
                width: 3.125rem;
                height: 3.125rem;
            }
        }

        &__toolbar {
            display: none;
        }

        &__profile {
            &-card-avatar,
            &-avatar {
                width: 2rem;
                height: 2rem;
            }
        }
    }

    .toolbar {
        max-width: 22.5rem;
        margin: 0 auto;
    }

    .settings {
        .header__profile {
            display: none;
        }

        .header__settings {
            display: block;
            img {
                width: 3.125rem;
                height: 3.125rem;
            }
        }
    }
}
